/* GuessRow.css */

.animate-pop {
    animation: pop 0.8s linear;
  }
  
  @keyframes pop {
    0% {
      transform: scale(0.75);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1.0);
      opacity: 1;
    }
  }  